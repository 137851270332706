 <template>
  <validation-observer ref="AddRemappingForm">
    <form ref="form" @submit.prevent="addMapping">
      <b-form-group label="Company Name" label-for="Company Name">
        <!-- <validation-provider
          #default="{ errors }"
          name="Company Name"
          rules="required"
        > -->
          <v-select
            v-model="selectedCompany"
            label="text"
            taggable
            placeholder="--Select Company--"
            :options="company_filter_options"
            autocomplete
            style="width: 100%"
          />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider> -->
      </b-form-group>
      <div class="text-center" v-if="this.selectedCompany == null">
        <div>
            <p>If not found organization please click on Add New Organization </p>
        </div>
        <b-button
          @click="addNewOrganization()"
          variant="outline-warning"
          :disabled="isdisable"
          size="sm"
        >
          <span v-if="isloading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else> Add New Organization</span>
        </b-button>
      </div>
      <b-button v-if="this.selectedCompany != null" type="submit" variant="primary" :disabled="isdisable" size="sm">
        <span v-if="isloading"
          ><b-spinner type="border" small></b-spinner> Please wait</span
        >
        <span v-else> Update</span>
      </b-button>
    </form>
  </validation-observer>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BFormSelect,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import vSelect from "vue-select";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    BFormFile,
    vSelect,
  },
  props: {
    org_id: {
      type: Number,
      required: true,
    },
    callBack: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isloading: false,
      isdisable: false,

      selectedCompany: null,
      company_filter_options: [],
      item: [],
    };
  },
  created: function () {
    this.load();
  },
  methods: {
    load: function () {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "organization/vciso-customers",
      };
      var self = this;
      this.$http(options).then((res) => {
        this.$http(options).then((res) => {
          this.item = res.data.data.map(function (value) {
            return {
              value: value.tenant_uid,
              text: value.name,
            };
          });
          self.company_filter_options.push(...this.item);
        });
      });
    },
    addMapping: function () {
      this.isloading = true;
      this.isdisable = true;
      let data = {
        name: this.selectedCompany.text,
        tenant_uid: this.selectedCompany.value,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.org_id +
          "/org-remapp/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "error") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.callBack();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.callBack();
        }
        this.isloading = false;
        this.isdisable = false;
      });
    },
    addNewOrganization() {
    //   this.isloading = true;
      this.isdisable = true;
      let data = {
        is_add_new: true,
      };
      const options = {
        method: "POST",
        data: data,
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.org_id +
          "/org-remapp/",
      };
      var self = this;
      this.$http(options).then((res) => {
        if (res.data.status == "error") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
          this.callBack();
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.callBack();
        }
        // this.isloading = false;
        this.isdisable = false;
      });
    },
  },
};
</script>