<template>
  <b-card-code no-body>
    <!-- {{items}} -->
    <div class="d-flex align-items-center">
      <div class="ml-1">
        <b-input-group class="input-group-merge">
          <!-- <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" class="text-muted" />
              </b-input-group-prepend> -->
          <b-form-input
            placeholder="Search Organization"
            @change="searchFn"
            v-model="name_filter"
            size="sm"
          />
          <b-input-group-append>
            <b-button variant="primary" size="sm"
              ><feather-icon
                icon="SearchIcon"
                class="text-white"
                @click="searchFn"
            /></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <b-button
        v-if="
          this.$store.state.app.user.permissions.includes(
            'organization.add_organization'
          )
        "
        @click="addOrg()"
        type="button"
        variant="primary"
        class="ml-auto m-1"
        size="sm"
      >
        Add New
      </b-button>
    </div>

    <b-table
      id="orgTable"
      :fields="fields"
      :items="items"
      :per-page="perPage"
      hover
      responsive
      style="min-height: 200px"
    >
      <template #cell(index)="data">
        {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(org_name)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            variant="primary"
            size="sm"
            :text="data.item.org_name.charAt(0)"
          />
          <span class="d-flex" style="margin-left: 5px">{{
            data.item.org_name
          }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <!-- <button @click="editOrg(data.item.org_id)" type="button" class="btn btn-primary btn-sm mr-1" style="position: relative;"> Edit</button>
        <button @click="deleteOrg(data.item.org_id)" type="button" class="btn btn-danger btn-sm mr-1" style="position: relative;"> Delete </button> -->
        <b-dropdown
          size="sm"
          class="ml-1"
          variant="outline-primary"
          text="Actions"
        >
          <!-- <b-dropdown-item @click="invitationOrg(data.item.org_id)"
            >Send Invitation</b-dropdown-item
          > -->
          <b-dropdown-item @click="editOrg(data.item.org_id)"
            >Edit</b-dropdown-item
          >
          <b-dropdown-item
            @click="generateReport(data.item.org_id, data.item.org_name)"
            >Generate Report</b-dropdown-item
          >
          <b-dropdown-item @click="gotoCredit(data.item.org_id,data.item.sso_ref_id)"
            >Credit Balance</b-dropdown-item
          >
          <b-dropdown-item v-if="superAdmin == 1" @click="gotoRemapping(data.item.org_id)"
            >Remap</b-dropdown-item
          >
          <b-dropdown-divider />
          <b-dropdown-item
            @click="gotoDelete(data.item.org_id, data.item.org_name)"
            >Delete</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </b-table>

    <b-pagination-nav
      v-model="currentPage"
      :number-of-pages="total"
      :link-gen="linkGen"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
      use-router
    />

    <!-- <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="orgTable"
    /> -->
    <b-modal
      ref="report_generator"
      title="Generate Report"
      hide-footer
      size="lg"
    >
      <ReportGenerator
        :report_type="parseInt(0)"
        :org_id="id"
        :parentCallBack="closeModal"
      />
    </b-modal>
    <b-modal
      id="modal-danger"
      hide-footer
      modal-class="modal-danger"
      centered
      title="Delete.. !"
      v-model="openDeleteModal"
      size="lg"
    >
      <b-card-text>
        Do you really want to delete
        <span class="text-primary">"{{ org_name }}"</span> ?
      </b-card-text>
      <div class="d-flex justify-content-end">
        <b-button @click="deleteOrg()" variant="danger" size="sm">
          Delete
        </b-button>
      </div>
    </b-modal>
    <b-modal
      ref="modal-remapping"
      hide-footer
      modal-class="modal-primary"
      centered
      title="Remap"
      v-model="openRemappingModal"
      no-close-on-backdrop
      size="lg"
    >
      <Remapping :callBack="closeModal" :org_id="id" />
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";
import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BCardText,
  BAvatar,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReportGenerator from "./../common/components/ReportGenerator.vue";
import Remapping from "./components/remapping.vue";
export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ReportGenerator,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BCardText,
    BAvatar,
    Remapping,
  },
  data() {
    return {
      superAdmin:this.$store.state.app.user.id,
      pagination_pos: "center",
      // Call orgaization API and return in this format
      items: [],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      total: 1,
      id: 0,
      fields: [
        { key: "index", label: "#" },
        // { key: "org_id", label: "#" },
        { key: "org_name", label: "Name" },
        { key: "org_nick_name", label: "Nick Name" },
        { key: "org_address", label: "Address" },
        { key: "sso_org_name", label: "Reference Name" },
        { key: "actions", label: "Actions" },
      ],
      name_filter: "",
      org_name: "",
      org_nameid: "",
      openDeleteModal: false,
      openRemappingModal: false,
    };
  },
  created: function () {
    this.load();
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.searchFn(false);
    },
  },
  methods: {
    linkGen: function (pageNum) {
      let current_path = this.$router.currentRoute.path + "?page=" + pageNum;
      return current_path;
    },
    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_BASEURL + "organization/organization",
      };
      this.$http(options).then((res) => {
        console.log(res.data);
        // this.toastMessage(res)  // method call to method2
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        //   this.currentPage = 1
        //   this.perPage = 10
        //   this.rows = res.data.count
      });
    },
    searchFn: function (reset = true) {
      this.filter = [];
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "organization/organization/?page=" +
        this.currentPage;
      if (this.name_filter != null) {
        this.filter.push({ name: this.name_filter });
        url = url + "&org_name=" + this.name_filter;
      }
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };
      this.$http(options).then((res) => {
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
      });
    },
    addOrg() {
      this.$router.push({ name: "Add Organization" });
    },
    editOrg(id) {
      this.$router.push({ name: "Edit Organization", params: { id: id } });
    },
    allocateScanners(id) {
      this.$router.push({ name: "Allocated Scanners", params: { id: id } });
    },
    gotoCredit(id,ref_id) {
      this.$router.push({ name: "Credit Balance", params: { id: id ,ref_id:ref_id} });
    },
    gotoDelete(org_id, org_name) {
      this.openDeleteModal = true;
      this.org_nameid = org_id;
      this.org_name = org_name;
    },
    deleteOrg() {
      // this.org_name = org_name;
      // if (confirm("Do you really want to delete " + this.org_name + "?")) {
      const options = {
        method: "DELETE",
        headers: { "content-type": "application/json" },
        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          this.org_nameid +
          "/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res);
        // if (res.data.status == "success") {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Deleted Successfully",
            icon: "EditIcon",
            variant: "success",
          },
        });
        // Remove from store
        let org_to_be_removed = {
          value: this.org_nameid,
          text: this.org_name,
        };
        this.$store.state.app.organizations.splice(
          this.$store.state.app.organizations.findIndex(
            (a) => a.value === org_to_be_removed.value
          ),
          1
        );
        if (this.$store.state.app.org_id == this.org_nameid) {
          this.$store.dispatch("app/getOrg", null);
        }
        // }
        this.openDeleteModal = false;
        this.load();
        //if(res.data.org_id){
        // this.$router.push({name: 'Organizations'});
        // }
      });
      // }
    },
    generateReport(id, name) {
      this.id = id;
      this.$refs["report_generator"].show();
    },
    closeModal() {
      this.$refs["report_generator"].hide();
    },
    invitationOrg(id) {
      // debugger;
      const options = {
        method: "POST",
        headers: { "content-type": "application/json" },

        url:
          process.env.VUE_APP_BASEURL +
          "organization/organization/" +
          id +
          "/send-invitation/",
      };
      var self = this;
      this.$http(options).then((res) => {
        console.log(res.data, "message");
        if (res.data.status == "success") {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        }
      });
    },
    gotoRemapping(id) {
      this.id = id;
      this.openRemappingModal = true;
    },

    closeModal: function () {
      this.$refs["modal-remapping"].hide();
      this.load();
    },
  },
};
</script>
<style scoped>
::v-deep .dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
}
</style>
